// const formatDate = (date) => {
//   const currentDate = new Date(date)
//   const dateTimeFormat = new Intl.DateTimeFormat('pl', { year: 'numeric', month: 'long', day: '2-digit' })
//   const [{ value: day },,{ value: month },,{ value: year }] = dateTimeFormat.formatToParts(currentDate);
//   return `${day} ${month} ${year}`;
// }

function formatDate(date) {
  var monthNames = ["sty", "lut", "mar", "kwi", "maj", "cze",
    "lip", "sie", "wrz", "paź", "lis", "gru"
  ];

  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  return [day, monthNames[month], year].join(' ');
}

export default formatDate;