import React from 'react';
import PropType from 'prop-types';
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import cs from 'classnames';
import styles from './PodcastTile.module.scss';
import formatDate from 'src/components/pages/podcasts/PodcastTile/formatDate';
import createSlug from 'src/components/shared/createSlug/createSlug';

const PodcastTile = ({ podcast, article }) => {
  const { title, miniature, shortDescription, meta: {createdAt} } = podcast;
  return (
    <Link to={!article ? `/slucham/${createSlug(title)}` :  `/czytam/${createSlug(title)}`} className={cs(styles.tileWrap, {[styles.articleTile]: article})}>
      <div className={styles.podcastInfoWrap}>
        <div className={styles.miniature}>
          <Image fluid={miniature.fluid} alt={miniature.alt} className={styles.img}/>
        </div>
        <div className={styles.podcastInfo}>
          <p className={styles.date}>{formatDate(createdAt)}</p>
          {/*<p className={styles.date}>{createdAt}</p>*/}
          <h2 className={styles.title}>
            {title}
          </h2>
          <p className={styles.description}>
            {shortDescription}
          </p>
        </div>
      </div>
      {!article &&
        <div className={styles.buttons}>
          <Link to={`/slucham/${createSlug(title)}`} className={cs(styles.button, styles.playIcon)}/>
          <Link to="/" className={cs(styles.button, styles.shareIcon)}/>
        </div>
      }
      {article &&
        <Link className={styles.mobileArrow} to={`/czytam/${createSlug(title)}`}/>
      }
    </Link>
  );
};

PodcastTile.propType = {
  podcast: PropType.object.isRequired,
  article: PropType.bool,
};

PodcastTile.defaultProps = {
  article: false,
};

export default PodcastTile;
