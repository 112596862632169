import React from 'react';
import { graphql } from 'gatsby';
import cs from 'classnames';
import styles from './slucham.module.scss'
import SEO from 'src/components/seo';
import Layout from 'src/Theme/layout';
import BigArticleTitle from 'src/components/shared/texts/BigArticleTitle/BigArticleTitle';
import PodcastList from 'src/components/pages/podcasts/PodcastList/PodcastList';

const Slucham = ({data}) => {
  const { allDatoCmsPodcast: { nodes: podcasts} } = data;
  return (
    <Layout>
      <SEO title="Slucham"/>
      <div className={cs(styles.podcastsWrap, 'firstSectionWrap')}>
        <BigArticleTitle className={styles.podcastsTitle}>Slucham</BigArticleTitle>
        <PodcastList data={podcasts}/>
      </div>
    </Layout>
  );
};

export const query = graphql`
    {
        allDatoCmsPodcast {
            nodes{
                title
                miniature {
                  alt
                    fluid(maxWidth: 900) {
                        ...GatsbyDatoCmsFluid_tracedSVG
                    }
                }
                shortDescription
                id
                meta{
                    createdAt
                }
            }
        }
    }
`;

export default Slucham;
